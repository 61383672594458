import React, { Component } from 'react';

import NFTApproval from '../components/Approve/NFTApproval';
class ApproveTheme extends Component {
    render() {
        return (
            <div className="main">
                <NFTApproval />
            </div>
        );
    }
}

export default ApproveTheme;