import React, { Component } from 'react';
import Login from '../components/Login/Login';

class Home extends Component {
    render() {
        return (
            <div className="main">
              <Login />
            </div>
        );
    }
}

export default Home;
