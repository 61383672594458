export const erc721mumbai = [
    {
        id: 1,
        chain: 80001,
        symbol: "matic",
        name: "Azuki",
        address: "0x10B8b56D53bFA5e374f38e6C0830BAd4ebeE33E6",
        pic: "https://i.seadn.io/gae/H8jOCJuQokNqGBpkBN5wk1oZwO7LM8bNnrHCaekV2nKjnCqw6UB5oaH8XyNeBDj6bA_n1mjejzhFQUP3O1NfjFLHr3FOaeHcTOOT?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "azukimumbai",
    },
    {
        id: 2,
        chain: 80001,
        symbol: "matic",
        name: "BoredApeYachtClub",
        address: "0xD0664568C474cebf15a8bA5DBe61b8A1475aBB78",
        pic: "https://i.seadn.io/gae/Ju9CkWtV-1Okvf45wo8UctR-M9He2PjILP0oOvxE89AyiPPGtrR3gysu1Zgy0hjd2xKIgjJJtWIc0ybj4Vd7wv8t3pxDGHoJBzDB?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "boredapeyachtclubmumbai",
    },
    {
        id: 3,
        chain: 80001,
        symbol: "matic",
        name: "CloneX",
        address: "0xDFef0ae167f9a649823772A733a34B06CAB7DcA8",
        pic: "https://i.seadn.io/gae/XN0XuD8Uh3jyRWNtPTFeXJg_ht8m5ofDx6aHklOiy4amhFuWUa0JaR6It49AH8tlnYS386Q0TW_-Lmedn0UET_ko1a3CbJGeu5iHMg?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "clonexmumbai",
    },
    {
        id: 4,
        chain: 80001,
        symbol: "matic",
        name: "Doodles",
        address: "0x06f36C3F77973317BEa50363A0F66646BcED7319",
        pic: "https://i.seadn.io/gae/7B0qai02OdHA8P_EOVK672qUliyjQdQDGNrACxs7WnTgZAkJa_wWURnIFKeOh5VTf8cfTqW3wQpozGedaC9mteKphEOtztls02RlWQ?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "doodlesmumbai",
    },
    {
        id: 5,
        chain: 80001,
        symbol: "matic",
        name: "Moonbirds",
        address: "0x3EeE83358E9ff3fC7ac46E8E5921fB2EC0a77fCb",
        pic: "https://i.seadn.io/gae/H-eyNE1MwL5ohL-tCfn_Xa1Sl9M9B4612tLYeUlQubzt4ewhr4huJIR5OLuyO3Z5PpJFSwdm7rq-TikAh7f5eUw338A2cy6HRH75?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "moonbirdsmumbai",
    },
    {
        id: 6,
        chain: 80001,
        symbol: "matic",
        name: "InvisibleFriends",
        address: "0xBCA3EB0C54F68ca559671906C65e2837991848bC",
        pic: "https://i.seadn.io/gae/lW22aEwUE0IqGaYm5HRiMS8DwkDwsdjPpprEqYnBqo2s7gSR-JqcYOjU9LM6p32ujG_YAEd72aDyox-pdCVK10G-u1qZ3zAsn2r9?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "invisiblefriendsgoerli",
    },
    {
        id: 7,
        chain: 80001,
        symbol: "matic",
        name: "MurakamiFlowers",
        address: "0xE29F8038d1A3445Ab22AD1373c65eC0a6E1161a4",
        pic: "https://i.seadn.io/gae/8g0poMCQ5J9SZHMsBrefrXbwzFmOQ-333l5OtbqqPW8TSGO9Stm2Rhd7kwHKsKIZPLxDjzISeeDTZ1H35t7GswPRoIfzTnNPsLs7rxw?auto=format&w=1920",
        scan: "https://mumbai.polygonscan.com/address/",
        opensea: "murakamiflowersmumbai",
    },
]
